import { NextUrqlPageContext } from 'next-urql'
import React from 'react'

import { ResolveCart } from '@/components/ResolveCart/ResolveCart'
import { VenueLanding } from '@/components/Venue/VenueLanding'
import { VenuePage } from '@/components/Venue/VenuePage'
import { VenuePageLoader } from '@/components/Venue/VenuePageLoader'
import { VenuePageV2 } from '@/components/VenueV2/VenuePageV2'
import { useTheme } from '@/contexts/ThemeContext'
import {
  OrderingTypeController,
  VenueController,
} from '@/contexts/VenueOrderContext'
import { Partner } from '@/gql/graphql'
import useLogOnMount from '@/hooks/useLogOnMount'
import { PartnerLoginContext, WithPartnerLogin } from '@/hooks/usePartnerLogin'
import { CartDefaults } from '@/lib/utils'
import { getTableInfo } from '@/utils/venue'

const MenuCategories = ({ tableNumber, tableArea }: CartDefaults) => {
  useLogOnMount('View venue categories')

  const { orderingRevampEnabled } = useTheme()

  return (
    <VenueController>
      <OrderingTypeController>
        <VenueLanding>
          <WithPartnerLogin partner={Partner.Eonx}>
            <PartnerLoginContext.Consumer>
              {({ partnerResolved }) => (
                <>
                  {!partnerResolved ? (
                    <VenuePageLoader categoriesCount={6} />
                  ) : (
                    <ResolveCart
                      tableArea={tableArea}
                      tableNumber={tableNumber}
                      loader={<VenuePageLoader categoriesCount={6} />}
                    >
                      {orderingRevampEnabled ? (
                        <VenuePageV2 />
                      ) : (
                        <VenuePage orderingTypePrompt={false} />
                      )}
                    </ResolveCart>
                  )}
                </>
              )}
            </PartnerLoginContext.Consumer>
          </WithPartnerLogin>
        </VenueLanding>
      </OrderingTypeController>
    </VenueController>
  )
}

MenuCategories.getInitialProps = (ctx: NextUrqlPageContext) => {
  return {
    ...getTableInfo(ctx.query),
  }
}

export default MenuCategories
